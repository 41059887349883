import { createTheme, rgbToHex } from "@material-ui/core"
import red from "@material-ui/core/colors/red"

export default createTheme ({
    palette: {
        primary: {
            main: '#8A1002'
        },
        whiteBackground: {
            main: '#ffffff'
        }
    }
})